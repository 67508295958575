/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 modelDraco.gltf --transform
*/

import React from 'react'
// , { useRef }
import { useGLTF } from '@react-three/drei'



export function Model(props) {
  const { nodes } = useGLTF('/modelWireframeEm/modelDraco.gltf')
  // , materials
  
  return (
    <group {...props} dispose={null}>
    
    {/* material={materials.PaletteMaterial002} */}
      <mesh geometry={nodes.logo2.geometry}  rotation={[Math.PI / 2, 0, 0]} scale={32
      } >
         
         {/* <meshPhysicalMaterial
          color="#c0c0c0"
          roughness={0.2}
          metalness={0.9}
          wireframe
        /> */}

         <meshPhongMaterial
        
          color="#673AB7"   
          roughness={0.1}   // Glatka površina za blagi odsjaj
          metalness={0.8}   // Povećana metalnost za refleksiju svetla
          transparent={false} // Nije providno
          transmission={0}  // Bez efekta prolaza svetlosti kroz materijal
          ior={1.45}        // Indeks prelamanja svetlosti (opcionalno za dodatne efekte)
          envMapIntensity={1} // Pojačan intenzitet refleksije okoline
          reflectivity={0.9}  // Visoka refleksivnost za sjajni efekat

           
        />
       
      </mesh>
     
    </group>
  )
}

useGLTF.preload('/modelWireframeEm/modelDraco.gltf')
