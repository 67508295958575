import React from 'react'
import './footer2.css';

const InstagramIcon = () => {
  return (

        

       <svg className='footer-social-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M224.44,170.71a53.34,53.34,0,1,0,53.36,53.34A53.34,53.34,0,0,0,224.44,170.71Zm124.71-41A54,54,0,0,0,318.74,99.3c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71-6.43,94.33s-1.85,73.27,6.47,94.34a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71,6.43-94.33s1.92-73.26-6.43-94.33ZM224.44,306a82,82,0,0,1-82-84c1-43.5,36.5-79,80-80a82,82,0,1,1,2.18,164Zm85.43-148A19.14,19.14,0,1,1,329,138.81v.05A19.14,19.14,0,0,1,309.91,158h0ZM400,0H48A48,48,0,0,0,0,48V400a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V48A48,48,0,0,0,400,0ZM383.32,290.05c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67C384.81,184.49,384.81,263.61,383.32,290.05Z"/></g></g></svg>

    
  )
}

export default InstagramIcon