import React from 'react'
import './footer2.css';

const FarcasterIcon = () => {
  return (

        

         <svg className='footer-social-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M400,0H48A48,48,0,0,0,0,48V400a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V48A48,48,0,0,0,400,0ZM297.6,315H247.77L224,230.49,200.22,315H150.5L97,133h57.63l22.12,82.92L198.9,133h50.61l22,82.32L293.41,133H351Z"/></g></g></svg>

    
  )
}

export default FarcasterIcon