
import React from 'react';

import "./servicesAnim.css";

import DevelopmentScene from "./DevelopmentSceneOpt2"
import ResearchScene from "./ResearchSceneOpt2"
 import ConsultingScene from "./ConsultingSceneOpt2"

const ServicesAnim = () => {

  return (
   <section id='services' >
     <div className='container main-servicesAnim'>
           <div className='row servicesAnim-content'>
             
                 <div className='col-lg-12 col-md-12 col-sm-12 servicesAnim'>
                    <h2>Services</h2>
                </div>
            </div>
          
           <div className='row servicesAnim-text-main'>  

           <div className='col-lg-6 col-md-6 col-sm-12 servicesAnim-col-scene'>
              
                     <DevelopmentScene/>

              
           </div>
          
           <div className='col-lg-5 col-md-5 col-sm-12 servicesAnim-col'>
             <h4 className='servicesAnim-subtitle'>Development</h4>
                      <p>Ethernal leverages on top-notch software development methods to provide end-to-end blockchain solutions.</p>

           </div>
            
            </div>
            
                   <div className='row servicesAnim-text-main servicesAnim-column-reverse'> 

                    <div className='col-lg-5 col-md-5 col-sm-12 servicesAnim-col'>
                        <h4 className='servicesAnim-subtitle'>Research</h4>
               <p>We strive for innovative solutions enabling new frontiers in Web3 technologies, eagerly tackling the most challenging problems.</p>
             </div>

                   <div className='col-lg-6 col-md-6 col-sm-12 servicesAnim-col-scene'>
                    
                      
                      <ResearchScene/>
                      
             </div>
             

            

             </div>

            
             <div className='row servicesAnim-text-main '> 

              <div className='col-lg-6 col-md-6 col-sm-12 servicesAnim-col-scene'>

              
               
                   <ConsultingScene/>
                   
            
             </div> 
            
             
             <div className='col-lg-5 col-md-5 col-sm-12 servicesAnim-col'>
                   <h4 className='servicesAnim-subtitle'>Consulting</h4>
              <p>We provide a range of services spanning from algorithms optimization and security auditing to design assessment and education.</p> 
             </div>
             

            </div>
            
        </div>
   </section>
  )
}

export default ServicesAnim
