import React,{useState, useEffect} from 'react'
// RiMenu3Line
import {RiMenuLine , RiCloseLine} from 'react-icons/ri';
import { Link as LinkScroll} from 'react-scroll';
import './navbar24.css'
 
 import logo from './../../assets/images/logo/ethernal-logo-mark-white-original.svg'

const Navbar24 = (props) => {

   const [navbar, setNavbar] = useState(true);
    //  const [activeLink, setActiveLink] = useState('home'); 
     const [scrollDirection, setScrollDirection] = useState('none'); 
     const [isMobile, setIsMobile] = useState(window.innerWidth <= 991.98);

    useEffect(() => {
    const changeBackground = () => {
      const currentScrollY = window.scrollY;

      if (!isMobile) {
        if (currentScrollY >= 0.01 && currentScrollY > scrollDirection) {
          setNavbar(false);
          setScrollDirection(currentScrollY);
        } else if (currentScrollY < scrollDirection || currentScrollY === 0) {
          setNavbar(true);
          setScrollDirection(currentScrollY);
        }
      }
    };

    

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991.98);
    };

    
    changeBackground();

    
    window.addEventListener('scroll', changeBackground);
    window.addEventListener('resize', handleResize);

    
    return () => {
      window.removeEventListener('scroll', changeBackground);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, scrollDirection]);



  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (

    
      <nav id='navbar' className={
        
    navbar
     ? 'navbar navbar-expand-lg navbar-light navbar-show' 
     : 'navbar navbar-expand-lg navbar-light navbar-hide'}>

  <div className="container-fluid">
    <div className='col navbar24-col1'>
        <LinkScroll className="navbar-brand" to="header">
      <img src={logo} alt="ethernal-logo-mark" className='logo-nav'/></LinkScroll> 
     

      {/* <LinkScroll
            className={`navbar-brand ${activeLink === 'home' ? 'active' : ''}`}
            to="header"
            onClick={() => setActiveLink('home')}
          >
            <img src={logo} alt="logo" className="logo-nav" />
            ethernal
          </LinkScroll>  */}

    </div>
    <div className='col navbar24-col2'>
       <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">
          <LinkScroll   className="nav-link"  to="services" activeClass="active">Services</LinkScroll> 
         {/*  <LinkScroll
                  className={`nav-link ${
                    activeLink === 'services' ? 'active' : ''
                  }`}
                  to="services"
                  activeClass="active"
                   onClick={() => setActiveLink('services')}
                 
                >
                  Services
                </LinkScroll> */}
        </li>
        <li className="nav-item">
        {/*   <LinkScroll
                  className={`nav-link ${
                    activeLink === 'projects' ? 'active' : ''
                  }`}
                  to="projects"
                  activeClass="active"
                   onClick={() => setActiveLink('projects')}
                  
                >
                  Projects
                </LinkScroll> */}
          <LinkScroll className="nav-link" to="projects" activeClass="active">Projects</LinkScroll> 
        </li>
        <li className="nav-item">
          <LinkScroll className="nav-link" to="about" activeClass="active">About</LinkScroll> 
           {/* <LinkScroll
                  className={`nav-link ${
                    activeLink === 'about' ? 'active' : ''
                  }`}
                  to="about"
                  activeClass="active"
                  onClick={() => setActiveLink('about')}
                >
                  About
                </LinkScroll> */}
        </li>
         <li className="nav-item">
          <LinkScroll className="nav-link " to="news" activeClass="active">News</LinkScroll>
           {/* <LinkScroll
                  className={`nav-link ${
                    activeLink === 'news' ? 'active' : ''
                  }`}
                  to="news"
                  activeClass="active"
                  onClick={() => setActiveLink('news')}
                >
                  News
                </LinkScroll>  */}
        </li>
        <li className="nav-item">
           <LinkScroll className="nav-link " to="careers" activeClass="active">Careers</LinkScroll> 
          {/* <LinkScroll
                  className={`nav-link ${
                    activeLink === 'careers' ? 'active' : ''
                  }`}
                  to="careers"
                  activeClass="active"
                  onClick={() => setActiveLink('careers')}
                >
                  Careers
                </LinkScroll> */}
        </li>
        
        <li className="nav-item">
          <LinkScroll className="nav-link " to="contact" activeClass="active">Contact</LinkScroll> 
          {/* <LinkScroll
                  className={`nav-link ${
                    activeLink === 'contact' ? 'active' : ''
                  }`}
                  to="contact"
                  activeClass="active"
                  onClick={() => setActiveLink('contact')}
                >
                  Contact
                </LinkScroll> */}
        </li>
        
      </ul>

       
    </div>

    </div>
    <div className='col navbar24-col3'>
       <button className="navbar-toggler" >

      
        
         <div className='menu-icon-new' onClick={handleClick}>

            {click
          ? 
          (navbar
          ? <RiCloseLine color="#E040FB" size={27} />
          : <RiCloseLine color="#E040FB" size={27}  /> )

          : 
          (navbar
          ? <RiMenuLine color="#ffffff" size={27} />
          : <RiMenuLine color="#ffffff" size={27}  /> )
          }

         

          </div>
          
      
      <ul className={click ? 'nav-menu-new active' : 'nav-menu-new'}>
            
            <li className='nav-item-new'>
              <LinkScroll
                to='services'
                
                className='nav-links-new'
               onClick={closeMobileMenu}
              >
                Services
              </LinkScroll>
            </li>
            <li className='nav-item-new'>
              <LinkScroll
                to='projects'
                 
                className='nav-links-new'
                onClick={closeMobileMenu}
              >
                Projects
              </LinkScroll>
            </li>
             <li className='nav-item-new'>
              <LinkScroll
                to='about'
                 
                className='nav-links-new'
                onClick={closeMobileMenu}
              >
                About
              </LinkScroll>
            </li>
             <li className='nav-item-new'>
              <LinkScroll
                to='news'
                
                className='nav-links-new nav-news-new'
                onClick={closeMobileMenu}
              >
                News
              </LinkScroll>
            </li>
            <li className='nav-item-new'>
              <LinkScroll
                to='careers'
                 
                className='nav-links-new nav-careers-new'
                onClick={closeMobileMenu}
              >
                Careers
              </LinkScroll>
            </li>
            
             <li className='nav-item-new'>
              <LinkScroll
                to='contact'
                
                className='nav-links-new nav-contact-new'
                onClick={closeMobileMenu}
              >
                Contact
              </LinkScroll>
            </li>
            
          </ul>
    

    </button>

    </div>
    
  </div>
</nav>
    
  
  )
}

export default Navbar24