import React from 'react';
import './about.css';



const About = () => {
  return (
    <section id="about">
              <div className="container aboutus-main">
               
                <div className="row aboutus-content">
                    <div className="col-lg-12 col-md-12 col-sm-12 aboutus-title">
                         <h2>About</h2>
                    </div>
                     <div className="col-lg-7 col-md-12 col-sm-12 aboutus aboutus1">
                        
                        <p>Ethernal represents a unique blend of highly skilled experts, both industry professionals and university professors. Our mission is to provide cutting edge blockchain solutions, and to spur adoption of the technology. Our goal is to become a hub of knowledge and expertise for the Web3 community, and a major contributor to the ecosystem, on both research and engineering fronts. </p>

                    </div>
                     
                </div>
                
            </div>
        </section>
        
  

  )
}

export default About
