import React from 'react'
import './header3.css'



const Header3 = () => {
 
  return (
    <section id='header'>
      
      <div className='container header3-main'>
       <div className='row header3-row'>
        
               <div className=' header3-title-wrapper animation-header '>
                
                  <h1 className= ' header3-title1'>We design and build</h1> 
                  <h1 className= ' header3-title1'>high performance</h1> 
                  <h1 className= ' header3-title1'>blockchain systems</h1> 
                  
               </div>

        
       </div>

      </div>
       
    </section>
  )
}

export default Header3
