//live
import React, { useRef,useState,useEffect, useMemo, Suspense } from 'react';
import './servicesAnim.css';

import { Canvas, useFrame} from '@react-three/fiber';
import { PerspectiveCamera, Float, Preload } from '@react-three/drei';
import * as THREE from 'three';

const sphereGeometryS = new THREE.SphereGeometry(0.7, 32, 16);

const lightProps = { color: 0xffffff, intensity: 1 };
const materialProps = { color: "#673AB7" };
// #0072c3
const wireframeMaterialProps = { color: "#E040FB", wireframe: true };
// #8a3ffc

const SphereS = () => {

  

  const material = useMemo(() => new THREE.MeshPhongMaterial(materialProps), []);
  return (
    <group>
      <mesh geometry={sphereGeometryS} position={[0, 0, 0]}>
        <primitive object={material} />
        <pointLight {...lightProps} position={[3, 3, 3]} />
      </mesh>
    </group>
  );
};

const SphereWf = (props) => {

  const geometryRef = useRef();
  const phiLengthRef = useRef(0);
  const isIncreasingRef = useRef(true);

  useFrame((state, delta) => {
    const { animationDuration = 2000, animationSpeed = 1 } = props;
    const speedFactor = 4;
    const phiChange = (delta * (Math.PI * 2)) / animationDuration * animationSpeed * speedFactor;

    if (geometryRef.current) {
      geometryRef.current.rotation.y += 0.003;
    }

    if (isIncreasingRef.current) {
      phiLengthRef.current += phiChange;
      if (phiLengthRef.current >= Math.PI * 2) {
        phiLengthRef.current = Math.PI * 2;
        isIncreasingRef.current = false;
      }
    } else {
      phiLengthRef.current -= phiChange;
      if (phiLengthRef.current <= 0) {
        phiLengthRef.current = 0;
        isIncreasingRef.current = true;
      }
    }

    if (geometryRef.current) {
      const phiLength = phiLengthRef.current;
      geometryRef.current.geometry.dispose();
      geometryRef.current.geometry = new THREE.SphereGeometry(1, 15, 9, 0, phiLength);
      geometryRef.current.geometry.computeBoundingSphere();
      geometryRef.current.geometry.verticesNeedUpdate = true;
    }
    
  });

  const wireframeMaterial = useMemo(() => new THREE.MeshBasicMaterial(wireframeMaterialProps), []);

  return (
    <group>
      <mesh ref={geometryRef}>
        {/* <sphereGeometry args={[1, 15, 9, 0, Math.PI * 2]} /> */}
        <primitive object={wireframeMaterial} />
      </mesh>
    </group>
  );
};


function ResearchSceneOpt2() {

   ////////////////////////////////////////
   // Definisanje stanja za dimenzije Canvas-a
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 300, height: 255 });
  const [fov, setFov] = useState(85); // Default vrednost

  useEffect(() => {
    // Funkcija koja ažurira dimenzije Canvas-a na osnovu širine ekrana
    const updateCanvasDimensions = () => {
      const width = window.innerWidth;
      let newCanvasWidth = 300;
      let newCanvasHeight = 255;

      if (width < 350) { // Primer: Ako je širina ekrana manja od 992px
        newCanvasWidth = 250; // Promenite ove vrednosti kako želite
        newCanvasHeight = 245; // Promenite ove vrednosti kako želite
      }

      setCanvasDimensions({ width: newCanvasWidth, height: newCanvasHeight });
    };

    // Prvi put pozivamo funkciju kad se komponenta mountuje
    updateCanvasDimensions();
    
    // Dodavanje event listenera koji će pozivati funkciju svaki put kad se promeni veličina ekrana
    window.addEventListener('resize', updateCanvasDimensions);
    
    // Uklanjanje event listenera kad se komponenta unmountuje
    return () => window.removeEventListener('resize', updateCanvasDimensions);
  }, []);

   useEffect(() => {
    // Funkcija koja ažurira fov na osnovu širine ekrana
    const updateFov = () => {
      const width = window.innerWidth;
      let newFov = 80; // Default vrednost
      
      if (width < 350) { // Primer: Ako je širina ekrana manja od 350px
        newFov = 88; 
      } else if (width < 992) { // Ako je širina ekrana između 768px i 992px
        newFov = 84;
      }

      setFov(newFov);
    };

    // Prvi put pozivamo funkciju kad se komponenta mountuje
    updateFov();
    
    // Dodavanje event listenera koji će pozivati funkciju svaki put kad se promeni veličina ekrana
    window.addEventListener('resize', updateFov);
    
    // Uklanjanje event listenera kad se komponenta unmountuje
    return () => window.removeEventListener('resize', updateFov);
  }, []);


  ///////////////////////////////////////

  // const isSmallScreen = window.innerWidth < 992; 
  // const fov = isSmallScreen ? 84 : 80; 


  return (
    <div className='servicesAnim_canvas_wrapper'>
       {/*  */}
    <Canvas   pixelRatio={2} style={{ width: canvasDimensions.width, height: canvasDimensions.height }}  invalidateFrameloop>
     
       <ambientLight {...lightProps} />
      <directionalLight {...lightProps} position={[0, 0, 2]} />
      <PerspectiveCamera makeDefault fov={fov} position={[0, 0, 2]} near={0.1} far={1000} />

      <Suspense fallback={null}>
        <Float>
          <SphereS />
          <SphereWf animationDuration={2000} animationSpeed={25} />
        </Float>
      </Suspense>
           
      <Preload all/>
    </Canvas>

    </div>

   
  );
}

export default ResearchSceneOpt2;
