
import React from 'react';

import './careers2.css';
//  import img from "../../assets/images/careers/careers.svg"
import HeaderScene from '../careers2/HeaderSceneOpt3'

const Careers2 = () => {

  return (
    <section id="careers">
     
       <div className="container careers2-main">

         <div className='row careers2-row'>
          <div className="col-lg-5 col-md-12 col-sm-12 careers2-col-content">

             <h2>Careers</h2>
                      
                     {/* <h3 className='careers2-h31'> We are hiring!</h3> */}

                       <p className='careers2-p1'>Ethernal is a team of committed Web3 contributors, appreciating ideas and achievements above all. We are always looking for talents who share our values. If you are passionate about building a decentralised future please <span className='no-wrap'>contact us!</span></p>  

                          
                         {/* <div className='col-lg-3 col-md-3 col-sm-3 careers2-btn'>
             <p><a href="mailto:info@ethernal.tech" className='no-wrap'>Join the team</a></p>
             </div> */}

                       {/* <a href="mailto:info@ethernal.tech" className='careers2-btn-new no-wrap'>
                          <span>Join the team</span>
                         </a> */}

                         <p className='careers2-p2'>Open positions</p>

                          <p className='careers2-open-position-link'><span>
                           <a href="https://www.linkedin.com/jobs/view/4099093188" target="_blank" rel="noopener noreferrer">
                              Business Development Representative
                         </a>
                            </span></p>

                         
              
                   
             </div>

             <div className='col-lg-6 col-md-12 col-sm-12 header-col-scene'>

          <HeaderScene/>
            
        </div>
           
                   
         </div>

        </div>
 
    </section>

  )
}

export default Careers2
