import React from 'react';
//  import {GrLinkedinOption, GrLinkedin} from 'react-icons/gr';
 
import "./team2.css";


export const Team1Img = (props) => {

//  const [isHover, setHover] = useState(false);
  

  return (
   <div className="team1-main">
            <div className="team-single-team">
              <div className="team-team-image-wrapper">
                <div className="team-team-image">
                  <img src={`../team/${props.img}`} alt={props.name} />
                 
                </div>

              </div>
              <div className="team-team-info">
                <h5 className='team-name1'>{props.name}</h5>
                <div className='team-team-info-linkedin'>
                 <h6 className='team-position'>{props.position}</h6>
               
                  {/* <a
                    onMouseEnter={()=> setHover(true)}
                    onMouseLeave={()=> setHover(false)}
                   href={props.link} 
                   target="_blank" 
                   rel="noopener noreferrer">
                    { isHover
                    ?  <GrLinkedin color="#0a66c2" size={16} /> 
                    :  <GrLinkedinOption color="#0a66c2" size={16} />  

                   }
                    
                     </a> */}
                   
                    
                   
                </div>
              </div>
              
            </div>
          </div>
  )
}
