const team1 = [
      {
        id: 1,
        img: "srdjan_vukmirovic.png",
        name: "Srdjan Vukmirovic, PhD",
        position: "CEO",
        link: "https://www.linkedin.com/in/srdjan-vukmirovic-a8b2632a/"
    },
    {
         id: 2,
        img: "nemanja_nedic.jpg",
        name: "Nemanja Nedic, PhD",
        position: "CTO",
         link: "https://www.linkedin.com/in/nemanja-nedic-181831144/"
    },
    {
         id: 3,
        img: "darko_capko.jpg",
        name: "Darko Capko, PhD",
        position: "CSO",
         link: "https://www.linkedin.com/in/darko-%C4%8Dapko-960a1587/"
    },
    {
         id: 4,
        img: "slobodan_lukovic.jpg",
        name: "Slobodan Lukovic, PhD",
        position: "VP of Partnerships",
         link: "https://www.linkedin.com/in/slukovic/"
    }
]
export default team1